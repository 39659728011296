@import '../../utils/helpers.sass'

.section.section-stepped-richtext
    padding-top: 25vh !important
    padding-bottom: 25vh !important
    .section-step-richtext-step-richtext
        font-size: 16px
        line-height: 32px
        .is-left-align
            float: left
        .is-right-align
            float: right
        p
            padding: 5px
            clear: both
        ul, ol
            clear: both
            text-align: left
        h3
            text-align: center

    .section-stepped-richtext-counter
        padding-top: 84px
        justify-content: space-evenly
    .section-stepped-richtext-step
        opacity: .5
        transition: opacity .25s ease
        &.is-active
            opacity: 1
    .section-stepped-richtext-counter-item
        color: rgba(255, 255, 255, .5)
        transition: color .25s ease
        &.is-active
            color: var(--color-secondary)

    @include wf-mobile-landscape
        padding-top: 60px !important
        padding-bottom: 60px !important
        .section-step-richtext-step-richtext
            font-size: 14px
            line-height: 28px