div.carousels
    -webkit-overflow-scrolling: touch
    --page-size: 3
    --page-item-gap: 10px

    overflow: auto
    width: 100%

    display: grid
    grid-auto-flow: column
    grid-column-gap: var(--page-item-gap)
    grid-auto-columns: calc((100% - (var(--page-item-gap) * (var(--page-size) - 1) )) / var(--page-size))

    grid-template-columns: unset
    grid-template-rows: unset

    > div, > a, > img
        width: auto
        margin: 0

/* Set Scroll Snap */
.is-scroll-snap-center
    scroll-snap-type: x mandatory
    scrollbar-width: none
    -ms-overflow-style: none

    > div, > a, > img
        scroll-snap-align: center

.is-scroll-snap-center::-webkit-scrollbar
        display: none