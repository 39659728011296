@import '../../utils/helpers.sass'
    
.slice-cards
    .section-cards-item
        a
            color: currentColor
        .section-cards-item-link
            margin-top: 20px
            text-align: left
            color: var(--color-secondary)

    @include wf-desktop
        .section-cards-container
            margin: 0px -15px 0px -15px
            padding: 20px 15px 40px 15px
            --page-item-gap: 15px
            &:after
                content: ''
            @include wf-mobile-portrait
                margin: 0
                padding: 20px 0 40px 0
                &:after
                    content: none
            .section-cards-item
                will-change: transform