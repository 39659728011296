@import '../../utils/helpers.sass'

.slice-quote
    .section-cards-full-container
        scrollbar-width: none
        -ms-overflow-style: none
        --page-size: 1
        &:-webkit-scrollbar
            display: none
        .section-cards-full-item-subtitle
            font-size: 14px
            line-height: 18px
            font-weight: normal
        .section-cards-full-item-title
            // font-style: italic
        .section-cards-full-item-link
            background-color: transparent

.modal-quote
    .modal-quote-inner
        color: var(--color-light)
        display: flex
        flex-direction: column
        align-items: center
        text-align: center
        padding: 100px
        max-width: 80vw

        .modal-quote-description
            font-size: 18px
            line-height: 44px
            margin-top: 40px

        @include wf-tablet
            padding: 50px
        @include wf-mobile-portrait
            padding: 30px
            max-width: 100vw