@import '../../utils/helpers.sass'

.section-visual-and-featured-items
    .section-visual-and-featured-items-list-item
        padding: 20px
        border-radius: 12px
        margin: 0px 20px 0px -20px
        transition: box-shadow .25s ease
        &:hover
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
        @include wf-mobile-landscape
            margin: 0px