@import '../../utils/helpers.sass'

.section.section-richtext-with-background
    .section-title ~ .section-richtext
        margin-top: 40px
    .section-richtext ~ .section-richtext
        margin-top: 40px

    .section-richtext
        font-size: 18px
        line-height: 44px
        .is-left-align
            float: left
        .is-right-align
            float: right
        p
            padding: 5px
            clear: both
        ul, ol
            clear: both
            text-align: left
    &.is-primary
        color: #000259
        .section-richtext
            font-size: 30px
            line-height: 48px
    &.is-primary-inverted
        background-color: #000259
        color: #ffffff
        .section-richtext
            font-size: 30px
            line-height: 48px
    &.is-overlay
        .section-richtext
            font-size: 30px
            line-height: 48px

    @include wf-mobile-landscape
        .section-richtext
            font-size: 14px
            line-height: 32px
        &.is-primary
            .section-richtext
                font-size: 22px
                line-height: 36px
        &.is-primary-inverted
            .section-richtext
                font-size: 22px
                line-height: 36px
        &.is-overlay
            .section-richtext
                font-size: 22px
                line-height: 36px