@import '../../utils/helpers.sass'

.slice-sectors-list
    @include wf-desktop
        .section-cards-container
            margin: 0px -15px 0px -15px
            padding: 20px 15px 40px 15px
            --page-item-gap: 15px
            &:after
                content: ''
            @include wf-mobile-portrait
                margin: 0
                padding: 20px 0 40px 0
                &:after
                    content: none

        .section-cards-item
            will-change: transform
            * 
                pointer-events: none

