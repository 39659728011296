@import '../../utils/helpers.sass'

.section.section-links
    .section-links-description
        margin-top: 40px
        margin-bottom: 20px
    .section-links-container
        text-align: center
    &.is-dark
        background-color: var(--color-dark-primary)
        color: var(--color-light)
        .section-links-container
            a
                color: currentColor