.slice-testimonials
    .section-cards-full-container
        --page-size: 1
    &.is-light
        .section-cards-full-item
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), var(--background)
            color: #000259
            .button-icon.button-icon-play
                background-image: url('../../assets/images/icon-play-dark.svg')
            .icon-quote
                background-image: url('../../assets/images/icon-quote-dark.svg')
            .section-cards-full-item-link
                color: #000259
