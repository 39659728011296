.slice-targets
    .section-richtext-full-container
        --page-size: 1
        .section-richtext-full-item
            padding-bottom: 40px
        .section-richtext-full-item.is-inverted
            .button
                /* color: var(--color-secondary) */
                color: var(--color-light)
                background-color: var(--color-dark)
        .section-richtext-full-item-richtext
            font-size: 30px
            line-height: 48px