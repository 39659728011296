@import '../../utils/helpers.sass'

.section-logo-gallery
    .section-logo-gallery-list
        --page-size: 5
        justify-content: normal

        &[data-count="1"], &[data-count="2"]
            justify-content: center
        @include wf-mobile-portrait
            --page-size: 3 !important

        .section-logo-gallery-list-item
            margin: 0
            padding: 0 10px
            width: 100%
    .pager
        display: none