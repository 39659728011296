@import '../../utils/helpers.sass'

.slice-solutions
    .section-cards-item-inner.is-full
        transition: transform .25s ease
        .section-cards-item-text:nth-of-type(2)
            display: none
        &:hover
            .section-cards-item-text:nth-of-type(1)
                display: none
            .section-cards-item-text:nth-of-type(2)
                display: block
        ul 
            padding-left: 20px
    
    @include wf-mobile-portrait
        .section-cards-item-outer
            min-height: 360px