@import '../../utils/helpers.sass'
    
.section-alternate-grid
    .section-alternate-grid-item-image
        width: 100%
        height: 100%
    .section-alternate-grid-item-text
        align-self: center
        font-size: 16px
        line-height: 32px
    .section-alternate-grid-item:nth-child(2n)
        .section-alternate-grid-item-image
            grid-column: 2
        .section-alternate-grid-item-text
            grid-column: 1

@include wf-tablet
    .section-alternate-grid
        .w-container.is-narrow
            padding: 0