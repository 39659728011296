@import '../utils/helpers.sass'

.search-bar-filter-cta
    background-position: 96.5% 50% !important
    p
        margin: 0

.search-bar-filter-cta
    transition: opacity .25s ease
.search-bar-filter
    @include wf-desktop
        &:hover
            .search-bar-filter-cta
                pointer-events: none
                opacity: 0
    @include wf-mobile-portrait
    max-width: 85vw