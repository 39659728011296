@import '../../utils/helpers.sass'

.section.section-features
    @include wf-desktop
        .section-features-container[data-count="1"], .section-features-container[data-count="2"]
            justify-content: center

    .section-features-item
        img
            max-height: 200px
            object-fit: contain
    &.is-primary
        color: var(--color-dark) !important
        .section-features-item-title
            color: var(--color-light)

    &.is-rounded
        @include wf-mobile-landscape
            padding-left: 30px
            padding-right: 30px
        .w-container
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
        .section-features-container
            width: min-content
            --page-size: 1
            --page-item-gap: 10px
            padding: 0
            border-radius: 80px
            background-color: var(--color-dark-primary)
            box-shadow: 0 64px 20px -64px var(--color-dark-primary)
            color: var(--color-light)
            .section-features-item
                padding: 20px 60px
            @include wf-mobile-landscape
                overflow-y: hidden
                width: 100%

    &.is-grid, &.is-grid-primary
        .section-features-container
            grid-auto-flow: initial
            grid-template-columns: repeat(4, minmax(0, 1fr))
            grid-template-rows: auto auto
            @include wf-tablet
                grid-template-columns: repeat(3, minmax(0, 1fr))
            @include wf-mobile-portrait
                grid-template-columns: repeat(2, minmax(0, 1fr))
        .pager
            display: none
    &.is-grid-primary
        background-color: var(--color-primary)
        color: var(--color-light)