@import '../../utils/helpers.sass'

.section-job-offers
    .section-job-offers-search
        .button
            white-space: nowrap
    .section-job-offers-cards
        justify-content: initial
    .section-job-offers-cards-item
        height: 100%
        overflow: hidden

    @include wf-desktop
        .section-job-offers-cards
            margin: -20px -20px -20px -20px
            padding: 20px 20px 60px 20px
            --page-item-gap: 20px
            // after element to have a padding at this end of scroll
            &:after
                content: ''
            .section-job-offers-cards-item
                transition: box-shadow .25s ease, transform .25s ease
                &:hover
                    box-shadow: 0 19px 18px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)
                    transform: scale(1.0125)