@import '../../utils/helpers.sass'

.section.section-richtext
    text-align: left
    .section-title ~ .section-richtext
        margin-top: 40px
    .section-richtext ~ .section-richtext
        margin-top: 40px

    .section-richtext
        font-size: 16px
        line-height: 32px
        .is-left-align
            float: left
        .is-right-align
            float: right
        p
            padding: 5px
            clear: both
        ul, ol
            clear: both
            text-align: left
        h3
            text-align: center
            color: var(--color-primary)
            font-size: 30px
            line-height: 48px
            font-weight: 400


    @include wf-mobile-landscape
        .section-richtext
            font-size: 14px
            line-height: 28px
            h3
                font-size: 22px
                line-height: 36px