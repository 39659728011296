@import '../../utils/helpers.sass'

.section.section-subsidiaries
    overflow: hidden
    min-height: 50vw !important
    .section-subsidiaries-map
        background: none
        svg
            width: 100%
            min-height: 100%
            /* background-color: #EBEBEB */
            .subsidiary
                transition: transform .25s ease
                transform-origin: 50% 50%
                transform-box: fill-box
                cursor: pointer
            .country.is-selected, .country.is-selected path
                fill: var(--color-secondary)
            .subsidiary.is-selected
                transform: scale(2)
                @supports (-ms-ime-align:auto)
                    transform: none
            .country:not(.is-selected):hover, .country:not(.is-selected) path
                opacity: .9
            .subsidiary:not(.is-selected):hover
                transform: scale(1.5)
                @supports (-ms-ime-align:auto)
                    transform: none
    .section-subsidiaries-detail-phone, .section-subsidiaries-detail-email
        color: currentColor
    .section-subsidiaries-detail-ctas
        text-align: center
        margin-bottom: 40px

.modal-subsidiaries
    .modal-subsidiaries-inner
        color: var(--color-light)
        display: flex
        flex-direction: row
        padding: 60px 100px
        max-width: 80vw
        .section-title
            text-align: left

        .modal-subsidiaries-detail
            padding-left: 60px
        .modal-subsidiaries-description
            font-size: 16px
            line-height: 32px
            padding: 20px 0
        ul
            list-style: none
            margin: 0
            padding: 0
            column-count: 2
            a
                color: currentColor
                font-size: 18px
                line-height: 32px

        @include wf-tablet
            padding: 50px
            .modal-subsidiaries-image
                display: none
        @include wf-mobile-portrait
            padding: 30px
            max-width: 100vw
