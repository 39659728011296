@import '../utils/helpers.sass'

.modal-scrollbar-measure 
    position: absolute
    top: -9999px
    width: 50px
    height: 50px
    overflow: scroll

.is-modal-shown
    overflow: hidden !important
    body
        padding-right: var(--scrollbar-width)
    
.modal-overlay
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: center
    z-index: 98
    height: 100%
    width: 100%
    background-color: rgb(1 16 61 / 90%),
    overflow-y: auto
    -webkit-overflow-scrolling: touch /* Lets it scroll lazy */
    backdrop-filter: blur(5px)

    .button-close
        height: 32px
        width: 32px
        background: center / contain no-repeat url('../assets/images/close-24px.svg')
        pointer: cursor
        position: absolute
        top: 100px
        right: 50px
        filter: invert(1)
        z-index: 100

    .modal
        position: relative
        z-index: 99
        display: flex
        flex-direction: column
        margin: auto


// .transition-modal-enter
//     opacity: 0.01
    
//     .modal
//         opacity: 0.01
//         transform: translate3d(0, 100vh, 0)

// .transition-modal-enter.transition-modal-enter-active
//     opacity: 1
//     transition: opacity 250ms ease-in

//     .modal
//         opacity: 1
//         transform: translate3d(0, 0, 0)
//         transition: opacity 250ms ease-in, transform 300ms cubic-bezier(0, 0, 0.11, 0.99)

// .transition-modal-exit
//     opacity: 1

//     .modal
//         opacity: 1
//         transform: translate3d(0, 0, 0)

// .transition-modal-exit.transition-modal-exit-active
//     opacity: 0.01
//     transition: opacity 200ms ease-in

//     .modal
//         opacity: 0.01
//         transition: opacity 200ms ease-in, transform 200ms cubic-bezier(0, 0, 0.11, 0.99)
//         transform: translate3d(0, -100vh, 0)

.transition-modal-enter
    opacity: 0.01
    
    .modal
        opacity: 0.01
        transform: translate3d(0, -100px, 0)

.transition-modal-enter.transition-modal-enter-active
    opacity: 1
    transition: opacity 250ms ease-in

    .modal
        opacity: 1
        transform: translate3d(0, 0, 0)
        transition: opacity 250ms ease-in, transform 300ms cubic-bezier(0, 0, 0.11, 0.99)

.transition-modal-exit
    opacity: 1

    .modal
        opacity: 1
        transform: translate3d(0, 0, 0)

.transition-modal-exit.transition-modal-exit-active
    opacity: 0.01
    transition: opacity 200ms ease-in

    .modal
        opacity: 0.01
        transition: opacity 200ms ease-in, transform 200ms cubic-bezier(0, 0, 0.11, 0.99)
        transform: translate3d(0, 100px, 0)

//
.transition-page-enter
    opacity: 0.01
    transform: translate3d(0, -100px, 0)

.transition-page-enter.transition-page-enter-active
    opacity: 1
    transform: translate3d(0, 0, 0)
    transition: opacity 250ms ease-in, transform 250ms cubic-bezier(0, 0, 0.11, 0.99)

.transition-page-exit
    opacity: 1
    transform: translate3d(0, 0, 0)

.transition-page-exit.transition-page-exit-active
    opacity: 0.01
    transition: opacity 250ms ease-in, transform 250ms cubic-bezier(0, 0, 0.11, 0.99)
    transform: translate3d(0, 100px, 0)


.modal
    [data-oembed-provider="YouTube"]
        position: relative
        padding-bottom: 56.25%
        height: 0
        overflow: hidden
        min-width: 80vw

        iframe
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%