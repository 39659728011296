@import '../../utils/helpers.sass'

.header
  z-index: 2 !important

.navbar-lang
  select
    border: 1px none #000
    background-color: #fff
    cursor: pointer

// .navbar-menu-list-item.has-sub-menu
//     .navbar-menu-list-item-label:after
//         content: '▼'
//         display: inline-block
//         font-size: 10px
//         margin-left: 8px

.burger-toggle
  width: 24px
  height: 24px
  display: flex
  flex-direction: column
  justify-content: space-between
  cursor: pointer
  transition: transform 330ms ease-out
  transform: rotate(0deg)
  --icon-thickness: 3px
  
  .line
    background-color: currentColor
    border-radius: var(--icon-thickness)
    width: 100%
    height: var(--icon-thickness)

    &.half
      width: 50%
    &.start
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57)
      transform-origin: right
    &.end
      align-self: flex-end
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57)
      transform-origin: left
    
.burger-toggle[data-checked=true]
  transform: rotate(-45deg)
  
  .line.start
    transform: rotate(-90deg) translateX(calc(var(--icon-thickness) / 2))

  .line.end
      transform: rotate(-90deg) translateX(calc(-1 * (var(--icon-thickness) / 2)))

.navbar-menu-list-item.has-sub-menu:hover .navbar-menu-list-item-label
    @include is-targeted
    color: initial

@include wf-desktop
  .navbar-sub-menu
    padding-top: 0 !important
  .header.is-menu-opened
      .navbar-menu-list-item.has-sub-menu:hover
          &:before
              content: ''
              position: absolute
              top: 100%
              width: calc(100vw - var(--scrollbar-width))
              left: 0
              height: 100vh
              backdrop-filter: blur(5px)
              background-color: rgba(1, 16, 61, 0.6)
              pointer-events: none
          .navbar-sub-menu
              display: block

  .navbar-sub-menu
    padding-left: 230px !important
    padding-right: 230px !important
  .navbar-sub-menu-list
    display: grid
    grid-template-columns: auto auto auto

@include wf-tablet
  .header
    .navbar-menu-list
      padding-left: 72px

  .navbar-menu-list-item.has-sub-menu.is-sub-menu-opened .navbar-menu-list-item-label
    @include is-targeted
    color: initial
  
  .navbar-menu-list-item-label
    margin-bottom: 10px

  .header.is-menu-opened
      .navbar-menu-list
          display: flex
          &:before
              content: ''
              position: absolute
              top: 100%
              width: 100vw
              left: 0
              height: 100vh
              backdrop-filter: blur(5px)
              background-color: rgba(1, 16, 61, 0.6)
              pointer-events: none

  .navbar-menu-list-item.is-sub-menu-opened
      .navbar-sub-menu
          display: block

@include wf-mobile-portrait
  .header
    .navbar-menu-list
      padding-left: 52px